* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

*:focus:not(:focus-visible) {
  outline: none;
}

input,
textarea {
  font: inherit;
}

a {
  text-decoration: inherit;
  color: inherit;
}

button {
  font: inherit;
  border: none;
  background: none;
  cursor: pointer;
}

html {
  --aqua: #68d3d9;
  --blue: #273158;
  --black: #fff;
  --lightBlue: #d4f2f5;
  --lightYellow: #ffe6ce;
  --lightGrey: #ecf1f8;
  --white: #fff;
  --yellow: #f7b06b;
  --green: #48ddc4;
  --grey: #b6bbc1;
  --red: #dc3545;
  --none: none;

  font-family: Lato, sans-serif;

  line-height: 1.4;
}

@media (min-width: 576px) {
}

@media (min-width: 768px) {
}

@media (min-width: 992px) {
}

@media (min-width: 1200px) {
}

body {
  overflow: auto !important;
}

h1,
h2,
h3,
h4 {
  font-family: Oswald, sans-serif;
  font-weight: 900;
  color: var(--blue);
}

h1 {
  font-size: 2.73rem;
  line-height: 3.36rem;
}

h2 {
  font-size: 1.81rem;
  line-height: 2.72rem;
}

h3 {
  font-family: Lato, sans-serif;
  font-size: 1.36rem;
  line-height: 1.82rem;
}

h4 {
  font-size: 1.36rem;
  line-height: 1.82rem;
}

h5 {
  font-size: 1.23rem;
  line-height: 1.45rem;
}

.ek-app-container h1,
.ek-app-container h2,
.ek-app-container h3,
.ek-app-container h4 {
  font-family: Lato, sans-serif;
  color: inherit;
}

.light {
  color: var(--white);
}

.active {
  color: var(--yellow);
}

.subdued {
  color: var(--grey);
  font-style: italic;
  font-weight: 500;
}

.uppercase {
  text-transform: uppercase;
}

p {
  font-weight: 500;
}

ul,
ol,
li {
  list-style: none;
}

.strong {
  font-weight: 900;
}

.small {
  font-size: 0.8rem;
}

.big {
  font-size: 1.2rem;
}

.c-fadeup {
  animation: fadeUp 1.2s cubic-bezier(0.23, 1, 0.32, 1) forwards 0.5s;
  opacity: 0;
}

@keyframes fadeUp {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.fadeIn {
  transition: opacity 0.5s;
  opacity: 0;
}

.fadeIn:hover {
  transition: opacity 0.5s;
  opacity: 1;
}

.fadeOut {
  transition: opacity 0.5s;
  opacity: 1;
}

.fadeOut:hover {
  transition: opacity 0.5s;
  opacity: 0;
}

.ek-describe.ek-isNotValid {
  display: none;
}

/* .ek-input.ek-isTouched.ek-isNotValid,
.validateForm .ek-input .ek-input.ek-isNotValid {
  border: 2px solid var(--yellow);
  border-radius: 5px;
} */

.ek-question__response {
  width: fit-content !important;
}

.ek-input label {
  margin: 0;
}

.ek-describe {
  padding: 0 !important;
  margin: 0 !important;
  height: unset !important;
}

.ek-describe.ek-isTouched,
.validateForm .ek-describe {
  display: initial;

  margin-left: auto;
  font-weight: 600;

  /* to ensure the notification takes up no vertical space */
  margin-top: -1rem;
  height: 1rem;
  padding-top: 1rem;
}

.ek-form-item,
.ek-question,
.ek-question__item {
  padding: 0 !important;
  margin: 0 !important;
}

.ek-form-item {
  margin-bottom: 0.6rem !important;
}

.ek-question__response {
  padding: 0 !important;
}

.ek-form-top-notification {
  margin-bottom: 0.5rem;
}

@media print {
  .no-print {
    display: none !important;
  }

  * {
    box-shadow: none !important;
    color: black !important;
  }

  h1,
  h2,
  h3,
  h4,
  h5 {
    color: var(--blue) !important;
  }
}

/* Hack for /module/week-3/abc-model */
.ek-section-4023 .ek-section-container {
  margin: 1rem;
  display: flex;
  flex-direction: column;
}

.ek-section-4023 .ek-section-container > div {
  width: 100%;
  position: relative;
}

.ek-section-4023 .ek-section-container > div:not(:last-child):after {
  position: absolute;
  background-color: var(--blue);
  content: '';
}
.ek-section-4023 .ek-section-container > div:not(:last-child):before {
  position: absolute;
  width: 1.5rem;
  height: 1.5rem;
  content: '';
  border-top: 0.3rem solid var(--blue);
  border-left: 0.3rem solid var(--blue);
}

@media screen and (max-width: 69.999em) {
  .ek-section-4023 .ek-section-container > div:not(:last-child) {
    margin-bottom: 3rem;
  }
  .ek-section-4023 .ek-section-container > div:not(:last-child):after {
    height: 2rem;
    width: 0.3rem;
  }
  .ek-section-4023 .ek-section-container > div:not(:last-child):after,
  .ek-section-4023 .ek-section-container > div:not(:last-child):before {
    bottom: -2rem;
    left: 50%;
  }

  .ek-section-4023 .ek-section-container > div:after {
    transform: translateX(0.75rem);
  }
  .ek-section-4023 .ek-section-container > div:before {
    transform: rotate(225deg);
  }
}

@media screen and (min-width: 70em) {
  .ek-section-4023 .ek-section-container {
    flex-direction: row;
  }
  .ek-section-4023 .ek-section-container > div:not(:last-child):after {
    width: 2rem;
    height: 0.3rem;
  }

  .ek-section-4023 .ek-section-container > div {
    width: calc(100% / 3 - 2rem);
  }
  .ek-section-4023 .ek-section-container > div:not(:last-child) {
    margin-right: 3rem;
  }
  .ek-section-4023 .ek-section-container > div:not(:last-child):after,
  .ek-section-4023 .ek-section-container > div:not(:last-child):before {
    right: -2rem;
    top: 50%;
  }

  .ek-section-4023 .ek-section-container > div:after {
    transform: translateY(0.75rem);
  }
  .ek-section-4023 .ek-section-container > div:before {
    transform: rotate(135deg);
  }
}

.ek-section-4023 .ek-container {
  margin: 1rem;
  height: 100%;
  width: auto;
}

.ek-section-4023 .ek-container .ek-snippet {
  padding: 0;
}

.ek-section-4023 .ek-container > div {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.ek-section-4023 .ek-container > div > div,
.ek-section-4023 .ek-container > div > div:last-child .ek-snippet {
  padding: 1rem;
  margin: 0;
}

.ek-section-4023 .ek-container > div > div:last-child {
  padding-top: 0;
}
.ek-section-4023 .ek-container > div > div:first-child {
  padding-bottom: 0;
}
.ek-section-4023 .ek-container > div > div:first-child .ek-text-snippet > div > div {
  flex-wrap: wrap;
}
/* End of Hack for /module/week-3/abc-model */

.ek-snippet-9447,
.ek-snippet-9448,
.ek-snippet-9450 {
  background-color: #273158 !important;
  border-radius: 1rem !important;
  padding: 1rem !important;
  display: flex;
  flex-direction: column;
}

.ek-snippet-9447::after,
.ek-snippet-9448::after,
.ek-snippet-9450::after {
  content: 'Done';
  text-transform: uppercase;
  background-color: var(--yellow);
  color: var(--blue);
  border-radius: 0.5rem;
  padding: 0.5rem 0.75rem;
  margin-left: auto;
  cursor: pointer;
  letter-spacing: 1px;
  line-height: 1;
}

.ek-snippet-9447 p,
.ek-snippet-9448 p,
.ek-snippet-9448 label,
.ek-snippet-9450 p,
.ek-snippet-9450 .ek-text-snippet p {
  color: var(--white);
}

.ek-snippet-9103 .ek-input label,
.ek-snippet-9721 .ek-input label,
.ek-snippet-9450 .ek-input label {
  /* background-color: var(--lightYellow); */
  margin-bottom: 1rem;
  padding: 1rem 1rem 1rem 3rem;
  border-radius: 5px;
}

.ek-snippet-9103 .ek-input label span,
.ek-snippet-9721 .ek-input label span,
.ek-snippet-9450 .ek-input label span {
  top: 1rem;
  left: 1rem;
  /* border: 1px solid var(--yellow); */
  border-radius: 2px;
  background-color: var(--white) !important;
}

.ek-snippet-9103 .ek-input label span::after,
.ek-snippet-9721 .ek-input label span::after,
.ek-snippet-9450 .ek-input label span::after {
  border-color: #7f7f7f;
}

.ek-question {
  display: flex;
  flex-direction: column;
  align-items: flex-start !important;
}

.ek-question__item,
.ek-question__response {
  width: 100% !important;
}

textarea.ek-input {
  min-height: 5rem;
}

.ek-form-item {
  background-color: transparent !important;
}

.ek-form-top-notification,
.ek-quesiton-mandatory-indicator {
  display: none;
}

.ek-describe {
  align-self: flex-end;
}

.ek-input {
  padding-top: 0.5rem;
}

.ek-input label {
  padding-bottom: 0.5rem;
}

input[type='checkbox'] + span {
  background-color: var(--white);
}

.ek-snippet .small-button {
  width: 100%;
}

.ek-snippet .medium-button {
  width: 100%;
}

.ek-snippet .large-button {
  font-size: 1.1rem;
  width: 100%;
}

/* Audio snippet style */

.ek__audio--accessible {
  background-color: #373c5b !important;
}

.ek__audio--accessible-title {
  margin: 0 0 1rem 0;
}

.rhap_main-controls-button,
.rhap_time,
.rhap_repeat-button,
.rhap_volume-button {
  color: #fff !important;
}

.rhap_progress-indicator,
.rhap_progress-filled,
.rhap_volume-indicator {
  background: #0c9ab9 !important;
}

.rhap_repeat-button {
  display: none;
}

.ek-audio {
  background-color: #373c5b;
  color: #fff;
  border-radius: 8px;
  margin: 1em 0;
  padding: 1em;
}

.ek-audio__controls {
  margin: 0 !important;
}

.ek-audio__outer {
  background-color: #00c7dd !important;
  height: 0.8em !important;
  margin-top: 0 !important;
  width: 0.8em !important;
}

.ek-audio__file p {
  animation: none !important;
  padding: 0 0 0 1em !important;
}

/* ********************************** */

@media (pointer: coarse), (pointer: none) {
  .ek-h-hide-on-touch-pointer {
    display: none;
  }
}

@media (pointer: fine) {
  .ek-h-hide-on-mouse-pointer {
    display: none;
  }
}
/*
  Orientate kanzi vertically on larger devices
  This can be removed once Kanzi provides this functionality
*/

@media (min-width: 1150px) {
  .kanzi-audio-player {
    left: 50% !important;
    top: 88vh !important;
  }
}

/* @media (min-width: 1500px) {
  .kanzi-audio-player {
    flex-direction: column !important;
    top: 14.5em !important;
    left: calc(100% - 1.75em) !important;
  }

  .kanzi-audio-controls {
    flex-direction: column !important;
  }

  .kanzi-audio-player .kanzi-speaker {
    border-radius: 0.15rem 0.15rem 0 0 !important;
    height: 0.8rem !important;
  }

  .kanzi-audio-volume {
    min-width: 140px !important;
    right: 0px !important;
    transform: translate(5%, -100%) rotate(270deg) !important;
    top: 62% !important;
  }

  .kanzi-language-menu {
    height: 100% !important;
    right: 0px !important;
    top: 50% !important;
    transform: translate(-35%, -50%) !important;
  }

  .kanzi-audio-volume .icon-kanzi-volume-mute,
  .kanzi-audio-volume .icon-kanzi-volume {
    transform: rotate(90deg);
  }

  .kanzi-brand {
    bottom: 0 !important;
    text-align: center !important;
    transform: translateY(100%) !important;
  }
} */

/* Kanzi overrides */
.kanzi-audio-player#kanzi_audio_player {
  flex-direction: column;
  left: 97.5% !important;
  top: 50% !important;
}

.kanzi-audio-controls {
  flex-direction: column;
}

.kanzi-audio-player .kanzi-speaker {
  border-radius: 0.15em 0.15em 0 0;
  height: 1rem;
}

.kanzi-brand {
  bottom: -40px !important;
}

.kanzi-audio-volume {
  right: 0;
  width: 8rem;
}

.ek-section-container li {
  list-style: disc;
  margin-bottom: 1rem;
}

.ek-section-container li::before {
  /* background-color: #000;
  border-radius: 50%;
  content: "" !important;
  left: 0;
  top: .6rem;
  color: rgb(0, 0, 0);
  height: .55rem;
  position: absolute;
  width: .55rem;
  z-index: 999999; */
  display: none;
}

/* .ek-app-container li {
  padding-left: unset;
}  */

/* //ek-app-container */
.ek-app-container li {
  list-style: none;
}

@keyframes fadeLeft {
  from {
    opacity: 0;
    transform: translateX(-10px);
  }

  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes fadeUp {
  from {
    opacity: 0;
    transform: translateY(10px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.a_fade--left {
  opacity: 0;
  animation: fadeLeft 1s;
  animation-delay: 0.7s;
  animation-fill-mode: forwards;
}

.a_fade--up {
  opacity: 0;
  animation: fadeUp 0.6s;
  animation-fill-mode: forwards;
  animation-delay: 0.5s;
  animation-timing-function: cubic-bezier(0.13, 0.43, 0.57, 0.88);
}

.a_fade--in {
  opacity: 0;
  animation: fadeIn 1s;
  animation-fill-mode: forwards;
  animation-delay: 0.4s;
}

.ek-section {
  opacity: 0;
  animation: fadeUp 1s;
  animation-delay: 0.5s;
  animation-fill-mode: backwards;
  padding: 0 1rem 1rem 1rem;
  opacity: 1;
}

/* Create more spacing on cards (ek-snippet has .9em x padding */
.ek-section-container {
  margin: 0 0 1rem 0 !important;
}

.ek-section-container:last-of-type {
  margin-bottom: 0 !important;
}

/* Add colour to drop downs - on container as content is separate to title */
.ek-event-reveal {
  animation: fadeUp 1s;
  animation-delay: 0.5s;
  animation-fill-mode: forwards;
  background-color: rgba(75, 194, 202, 0.1);
  border: none !important;
  margin: 0 1rem 1rem 1rem !important;
  opacity: 0;
}

.ek-event-reveal .ek-snippet {
  padding: 1rem;
}

.ek-event-reveal-title {
  padding-left: 2.2rem !important;
  position: relative;
}

.ek-event-reveal-title::before {
  content: '\272D';
  left: 1rem;
  position: absolute;
}

.large-button {
  font-size: 1.1rem !important;
  font-weight: 500 !important;
}

.ek-describe {
  display: none !important;
}

.ek-event-reveal > div:last-child {
  max-height: initial;
}

/* Ensure all activity grid items are consistently 50% on larger devices */
.c-list__item,
.c-story-hurdle {
  height: auto;
  width: 100%;
}

@media (min-width: 64em) {
  .c-list__item,
  .c-story-hurdle {
    height: auto;
    width: 50%;
  }
}
